.becomeAgentBttton {
    background-color: #77a32f !important;
    border-radius: 3px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #ffff !important;
    height: 1em !important;
    padding:1.4em 1.5em !important;
  }
  .link-style {
    text-decoration: none !important;
    color: white !important;
    font-style: italic !important;
    font-size: 1em !important;
    font-weight: bold !important;
  }
  